








































































































































import axios from 'axios';
import Modal from './../../packs/modal';
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      is_login: false,
      modal: null,
      student: {}
    }
  },
  mounted() { 
    this.get_me() ;
  },
  methods: {
    click_logout() {
      axios.post("/api/students/logout").then( res =>  {
        location.href = "/";
      });
    },
    close() {
      this.modal.hide();
    },
    submit_login() {

      let error_element = document.getElementById('login-modal-error') as HTMLElement;
      error_element.style.display = 'none';

      let login_name = (document.getElementById('login_name') as HTMLInputElement).value;
      let password = (document.getElementById('password') as HTMLInputElement).value;

      axios.post("/api/students/login", {login_name: login_name, password: password } ).then( res => {

        let data = res.data;
        if ( data.is_login ){
          location.reload()
        } else {
          error_element.style.display = 'block';
        }
      })
    },
    get_me() {
      axios.get("/api/students/me" ).then( 
      (res)=> {
        let data = res.data;

        if (data.is_login) {
          this.is_login = true;
          this.student = data.student;
        } else {
          this.is_login = false;
          this.student = {};
          this.modal = new Modal("login-modal");
          this.modal.bind(".open-login-modal");
        }

        var event = new CustomEvent("readyAuthorizer", {
          "detail": {
            "is_login": this.is_login,
            "student": this.student 
          }
        });
        document.dispatchEvent(event);

      })
    }
  }
});

