var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "gheader" }, [
    _vm._m(0),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _c("div", { attrs: { id: "app-student-profile" } }, [
      _vm.is_login == true
        ? _c("div", [
            _c("div", { staticClass: "sp-header" }, [
              _c("div", { staticClass: "sp-header__main" }, [
                _vm._m(2),
                _vm._v(" "),
                _c("div", { staticClass: "mini-prof" }, [
                  _c("div", { staticClass: "mini-prof__icon" }, [
                    _c("img", {
                      staticClass: "profile__icon",
                      attrs: { src: _vm.student.icon.url }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mini-prof__name" }, [
                    _vm._v(_vm._s(_vm.student.screen_name))
                  ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "sp-header__sub",
                  attrs: { id: "spNavSupportedContent" }
                },
                [
                  _c("div", { staticClass: "widget profile" }, [
                    _c("div", { staticClass: "widget__inner" }, [
                      _c("div", { staticClass: "money-box" }, [
                        _c("div", { staticClass: "profile__money" }, [
                          _c("div", { staticClass: "profile__money-inner" }, [
                            _c("span", { staticClass: "icon" }, [
                              _vm._v("ゴールド")
                            ]),
                            _c("span", { staticClass: "gold-holder" }, [
                              _vm._v(_vm._s(_vm.student.gold))
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "profile__money" }, [
                          _c("div", { staticClass: "profile__money-inner" }, [
                            _vm._m(3),
                            _c("span", { staticClass: "ticket-holder" }, [
                              _vm._v(_vm._s(_vm.student.ticket_count))
                            ])
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("ul", { staticClass: "sp-nav-list" }, [
                        _c("li", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "/students/" + _vm.student.login_name
                              }
                            },
                            [_vm._v("ぼくわたしのしま")]
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "/students/" +
                                  _vm.student.login_name +
                                  "/monsters"
                              }
                            },
                            [_vm._v("モンスター図鑑")]
                          )
                        ]),
                        _vm._v(" "),
                        _vm._m(4),
                        _vm._v(" "),
                        _vm._m(5),
                        _vm._v(" "),
                        _vm._m(6),
                        _vm._v(" "),
                        _vm._m(7)
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "mod_btn btn-cancel btn-s my-5",
                        attrs: { type: "button", id: "btn-student-logout" },
                        on: { click: _vm.click_logout }
                      },
                      [_vm._v("ログアウト")]
                    )
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "gheader__login-box status-logon" }, [
              _c("div", { staticClass: "gheader__login-box-inner" }, [
                _c("div", { staticClass: "mini-prof" }, [
                  _c("div", { staticClass: "mini-prof__icon" }, [
                    _c("img", {
                      staticClass: "profile__icon",
                      attrs: { src: _vm.student.icon.url }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mini-prof__name" }, [
                    _vm._v(_vm._s(_vm.student.screen_name))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "gheader__logout" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-border btn-primary",
                      attrs: { type: "button", id: "btn-student-logout" },
                      on: { click: _vm.click_logout }
                    },
                    [_vm._v("ログアウト")]
                  )
                ])
              ])
            ])
          ])
        : _c("div", [
            _c("div", { staticClass: "gheader__login-box" }, [
              _c("div", { staticClass: "sp-header status-logout" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary open-login-modal",
                    attrs: { type: "button" }
                  },
                  [_vm._v("\n          ログイン\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "modal", attrs: { id: "login-modal" } },
                  [
                    _c("div", { staticClass: "modal-dialog modal-login" }, [
                      _vm._m(8),
                      _vm._v(" "),
                      _c("div", { staticClass: "modal-content" }, [
                        _c("div", { staticClass: "modal-header" }, [
                          _c("h5", { staticClass: "modal-title" }, [
                            _vm._v("ログイン")
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "close",
                              attrs: { type: "button" },
                              on: { click: _vm.close }
                            },
                            [_c("span", [_vm._v("×")])]
                          )
                        ]),
                        _vm._v(" "),
                        _vm._m(9),
                        _vm._v(" "),
                        _c("div", { staticClass: "modal-footer" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary  btn-login",
                              attrs: { type: "button" },
                              on: { click: _vm.submit_login }
                            },
                            [_vm._v("ログイン")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary",
                              attrs: { type: "button" },
                              on: { click: _vm.close }
                            },
                            [_vm._v("閉じる")]
                          )
                        ]),
                        _vm._v(" "),
                        _vm._m(10)
                      ])
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { href: "/students/new" }
                  },
                  [_vm._v("とうろくする")]
                )
              ])
            ])
          ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", { staticClass: "gheader__logo" }, [
      _c("a", { attrs: { href: "/" } }, [
        _c("img", {
          attrs: {
            src: "/statics/images/header_title@2x.png",
            alt: "おやこクエスト",
            id: "logo"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gheader__about-box" }, [
      _c(
        "a",
        {
          staticClass: "btn btn-primary",
          attrs: { href: "/docs/readme_for_guardian" }
        },
        [_vm._v("保護者の方へ")]
      ),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "btn btn-primary", attrs: { href: "/docs/help" } },
        [_vm._v("ヘルプ")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "hamburger-menu", attrs: { id: "toggleBtn" } },
      [
        _c("div", { staticClass: "on" }, [_vm._v("マイメニュー")]),
        _vm._v(" "),
        _c("div", { staticClass: "off" }, [_vm._v("閉じる")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("img", {
        attrs: {
          src: "/statics/images/icon/icon_ticket.png",
          width: "45",
          alt: "チケット"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("a", { attrs: { href: "/mypage/friends/" } }, [
        _vm._v("フレンドめいぼ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("a", { attrs: { href: "/mypage/ilands/edit" } }, [
        _vm._v("しまづくり")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("a", { attrs: { href: "/mypage/ilands/shop" } }, [
        _vm._v("しまショップ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("a", { attrs: { href: "/mypage/users/edit_icon" } }, [
        _vm._v("マイキャラの"),
        _c("br"),
        _vm._v("へんこう")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-login-img" }, [
      _c("img", {
        attrs: {
          src: "/statics/images/difficulty/difficulty_very_easy.svg",
          width: "200",
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-body" }, [
      _c(
        "div",
        {
          staticClass: "error",
          staticStyle: { display: "none" },
          attrs: { id: "login-modal-error" }
        },
        [
          _vm._v(
            "\n                  ろぐいんにしっぱいしました\n                "
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "form-group row" }, [
        _c(
          "label",
          {
            staticClass: "col-sm-4 col-form-label",
            attrs: { for: "login_name" }
          },
          [_vm._v("おやこやID")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-8" }, [
          _c("input", {
            staticClass: "form-control",
            attrs: { type: "text", name: "login_name", id: "login_name" }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group row" }, [
        _c(
          "label",
          {
            staticClass: "col-sm-4 col-form-label",
            attrs: { for: "password" }
          },
          [_vm._v("パスワード")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-8" }, [
          _c("input", {
            staticClass: "form-control",
            attrs: { type: "password", name: "password", id: "password" }
          })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-regist" }, [
      _c("div", { staticClass: "regist-text" }, [
        _vm._v("\n                  はじめてのときは"),
        _c("br"),
        _vm._v("とうろくしよう\n                ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "regist-button" }, [
        _c(
          "a",
          { staticClass: "btn btn-primary", attrs: { href: "/students/new" } },
          [_vm._v("とうろくする")]
        )
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-d486df56", { render: render, staticRenderFns: staticRenderFns })
  }
}